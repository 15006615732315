import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import { useData } from '../../../DataContext';



const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const paper = {
    /* backgroundColor: '#111111', */
    /* border: '2px solid #000', */
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', /* Replace with actual theme shadow value */
    padding: '16px 32px 24px' /* Replace with actual theme spacing values */
}


const AvatarModal = ({ children, src }) => {
    const [open, setOpen] = useState(false);
    const {themeChanger} = useData();

    return (
        <>
            <button onClick={() => setOpen(true)} className={`send ${themeChanger}`}>
                {children}
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={paper}>
                    <div className="avatar-flex">
                        <div className="modal-header">
                            <h4 className="modal-title avatar-title" id="transition-modal-title">Avatar</h4>
                            <a href={src} className="btn waves-effect waves-light btn-rounded btn-dark small btn-md" target='_blank' rel="noreferrer">Open Avatar</a>
                        </div>
                        <Close onClick={() => setOpen(false)} className='new-chat-close-btn' style={{ color: 'white' }} />
                    </div>
                    <iframe src={src} frameborder="0" width="100%" title='avtar_img' height="80%"></iframe>
                </div>
            </Modal>
        </>
    )
}

export default AvatarModal