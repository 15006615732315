import { useEffect, useRef, useState } from "react";
import "./Chat.css";
import botImg from "../../../images/bot.png";
import userImg from "../../../images/user-rounded.png";
import appaiIcon from "../../../images/ai-dark-icon.png";
import applightIcon from "../../../images/ai-light-icon.png";
import { Checkbox } from "@mui/material";
import TextToSpeech from "../../Features/TextToSpeech/TextToSpeech";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import InitialContent from "../InitialContent/InitialContent";
import { LiaTelegram } from "react-icons/lia";
import { BsFillMicFill } from "react-icons/bs";
import { useData } from "../../../DataContext";
import { AiFillRobot } from "react-icons/ai";
import AvatarModal from "../../Features/AvtarModal/AvtarModal";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getChat,
  getRooms,
  userChat,
} from "../../../Store/Slice/user/authSlice";
import axiosInstance from "../../../utils/axiosInstance";
import axios from "axios";
import { getFromLocalStorage } from "../../../utils/localStorage";
import { getRoomThunk } from "../../../Store/Slice/user/authThunk";

const Chat = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isLeftMenuVisible, setLeftMenuVisible] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isMicGlowing, setIsMicGlowing] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [routeId, setRouteId] = useState();
  const chatBodyRef = useRef(null);
  const [totalCost, setTotalCost] = useState(0);
  const { roomId } = useParams();
  const [colors, setColors] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    updateData,
    view,
    views,
    themeChanger,
    serverUrl,
    // selectiondata,
    setSelectionData,
    urlname,
  } = useData();

  const width = window?.innerWidth < 700;
  const loader = useSelector(
    (state) => state && state?.auth && state?.auth?.isLoading
  );
  const chatDetails = useSelector(
    (state) => state && state?.auth && state?.auth?.chat
  );
  const statusChangerChat = useSelector(
    (state) => state && state?.auth && state?.auth?.statusChanger?.addNewChat
  );


  // console.log('chatDetails', chatDetails);
  const filter = createFilterOptions();



  useEffect(() => {
    // console.log("yahi hai data", roomId, statusChangerChat);
    if (roomId && statusChangerChat) {
      dispatch(getRooms());
      dispatch(getChat(roomId));
    } else if (routeId && statusChangerChat) {
      navigate(routeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChangerChat]);
  
  

  // Function to remove "in python" from string
  function processString(inputString) {
    const suffix = "in python";
    if (inputString.toLowerCase().endsWith(suffix)) {
      return inputString.slice(0, -suffix.length).trim();
    }
    return inputString;
  }
  
  // Old Use Effect code
  // useEffect(() => {
  //   if (chatDetails && chatDetails.length > 0) {
  //     const chatDataPromises = chatDetails.map((chat) => {
  //       // Process the API_REQUEST string
  //       const processedRequest = processString(chat.API_REQUEST);

  //       const userMessage = {
  //         sender: "User",
  //         message: processedRequest,
  //       };

  //       // console.log( "yahi hai data",userMessage.message);
        
  //       const chatgptMessage = {
  //         sender: "ChatGPT",
  //         message: chat.API_RESPONSE,
  //         price: chat.OUTPUT_TOKEN_INFUSAI_PRICE || "0",
  //         promtToken: chat.REQUEST_TOKEN_SIZE || "0",
  //         responseToken: chat.RESPONSE_TOKEN || "0",
  //         totalToken: `${chat.REQUEST_TOKEN + chat.RESPONSE_TOKEN}` || "0",
  //         CurrentDate: "0",
  //         currentTime: "0",
  //         similarityScore: chat.ACCURACY_SCORE || "0",
  //         sourceDocument: "0",
  //         sourcePage: "0",
  //         id: chat.LOG_ID,
  //       };

  //       if (chatgptMessage.message.includes("import matplotlib.pyplot")) {
  //         const mySubString = chatgptMessage.message.substring(
  //           chatgptMessage.message.indexOf("import"),
  //           chatgptMessage.message.indexOf("plt.show()") + 10
  //         );

  //         const token = getFromLocalStorage("authtoken");

  //         return axios
  //           .post(
  //             `https://api.infusai.com/showGraph`,
  //             { pythonCode: mySubString },
  //             { headers: { Authorization: `Bearer ${token}` } }
  //           )
  //           .then((response) => {
  //             return [
  //               { sender: "User", message: userMessage.message },
  //               {
  //                 sender: "ChatGPT",
  //                 message: `data:image/png;base64, ${response.data}`,
  //                 price: chatgptMessage.price,
  //               },
  //             ];
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching the graph image: ", error);
  //             return [
  //               { sender: "User", message: userMessage.message },
  //               {
  //                 sender: "ChatGPT",
  //                 message: "Error generating graph image",
  //                 price: chatgptMessage.price,
  //               },
  //             ];
  //           });
  //       } else {
  //         return Promise.resolve([userMessage, chatgptMessage]);
  //       }
  //     });

  //     Promise.all(chatDataPromises).then((resolvedChatData) => {
  //       const chatData = resolvedChatData.flat();
  //       setMessages(chatData);
  //     });
  //   } else {
  //     setMessages([]);
  //   }
  // }, [chatDetails, setMessages]);

  useEffect(() => {
    if (chatDetails && chatDetails.length > 0) {
      const chatDataPromises = chatDetails.map((chat) => {
        // Process the API_REQUEST string
        const processedRequest = processString(chat.API_REQUEST);
  
        const userMessage = {
          id: `user-${chat.LOG_ID}`,  // Added unique ID
          sender: "User",
          message: processedRequest,
        };
  
        const chatgptMessage = {
          id: `chatgpt-${chat.LOG_ID}`,  // Added unique ID
          sender: "ChatGPT",
          message: chat.API_RESPONSE,
          price: chat.OUTPUT_TOKEN_INFUSAI_PRICE || "0",
          promptToken: chat.REQUEST_TOKEN_SIZE || "0",
          responseToken: chat.RESPONSE_TOKEN || "0",
          totalToken: `${chat.REQUEST_TOKEN + chat.RESPONSE_TOKEN}` || "0",
          CurrentDate: "0",
          currentTime: "0",
          similarityScore: chat.ACCURACY_SCORE || "0",
          sourceDocument: "0",
          sourcePage: "0",
        };
  
        if (chatgptMessage.message.includes("import matplotlib.pyplot")) {
          const mySubString = chatgptMessage.message.substring(
            chatgptMessage.message.indexOf("import"),
            chatgptMessage.message.indexOf("plt.show()") + 10
          );
  
          const token = getFromLocalStorage("authtoken");
  
          return axios
            .post(
              `https://api.infusai.com/showGraph`,
              { pythonCode: mySubString },
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
              return [
                userMessage,
                {
                  ...chatgptMessage,
                  message: `data:image/png;base64, ${response.data}`,
                },
              ];
            })
            .catch((error) => {
              console.error("Error fetching the graph image: ", error);
              return [
                userMessage,
                {
                  ...chatgptMessage,
                  message: "Error generating graph image",
                },
              ];
            });
        } else {
          return Promise.resolve([userMessage, chatgptMessage]);
        }
      });
  
      Promise.all(chatDataPromises).then((resolvedChatData) => {
        const newMessages = resolvedChatData.flat();
        setMessages((prevMessages) => {
          // Create a set of existing message IDs
          const existingMessageIds = new Set(prevMessages.map(msg => msg.id));
          // Filter new messages to only include those not already in the state
          const filteredNewMessages = newMessages.filter(newMsg => !existingMessageIds.has(newMsg.id));
          return [...prevMessages, ...filteredNewMessages];
        });
      });
    } else {
      setMessages([]);
    }
  }, [chatDetails]);
    

 
  // const location = useLocation();
 
  // // update chat after deletion
  // useEffect(() => {
  //   console.log("loc:",location.pathname)
  //   if(location.pathname === "/" || location.pathname ==="")
  //   setMessages([]);
  // }, [location]);


  useEffect(() => {
    if (roomId) {
      dispatch(getRooms());
      dispatch(getChat(roomId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);



  useEffect(() => {
    const content = {};
    // console.log('hiting update selection update')
    selectedMessages.forEach((index) => {
      const message = messages[index];
      const options = selectedOptions[index];

      // Handle single selected option
      if (typeof options === "string") {
        const messageWithOption = {
          template: options,
          message: message.message,
        };
        content[index] = [messageWithOption]; // Wrap in an array
      } else if (Array.isArray(options)) {
        // Handle multiple selected options
        const messageWithOption = options.map((option) => ({
          template: option,
          message: message.message,
        }));
        content[index] = messageWithOption;
      }
    });

    // Initialize an empty array to store the reformatted data
    const reformattedArray = [];

    // Iterate through the arrays within the object and reformat the data
    for (const key in content) {
      content[key].forEach(async (item) => {
        const value = item.message;
        const resizeImage = (imageData, width, height) => {
          return new Promise((resolve, reject) => {
            if (imageData.startsWith("data:image")) {
              const img = new Image();
              img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                const resizedImage = canvas.toDataURL("image/png", 1.0);
                resolve(resizedImage);
              };
              // // console.log('Resized Image Dimensions:', img.width, img.height);
              img.src = imageData;
            } else {
              resolve(imageData);
            }
          });
        };
        const resizedMessage = await resizeImage(value, 800, 500);
        reformattedArray.push({
          template: item.template,
          message: resizedMessage,
        });
      });
    }

    setSelectionData(reformattedArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMessages, selectedOptions]);

  const top100Films = [
    { title: "Scope" },
    { title: "Background" },
    { title: "Risks" },
    { title: "Recommendation" },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    updateData([messages, selectedMessages, selectedOptions, totalCost]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, selectedMessages, selectedOptions, totalCost]);

  const handleSelectOptions = (index, newValue) => {
    var option = "";

    if (typeof newValue === "string") {
      option = newValue?.title;
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input

      option = newValue.inputValue;
    } else {
      option = newValue?.title;
    }

    console.log("option", option);

    setSelectedOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
      if (Array.isArray(updatedOptions[index])) {
        const optionIndex = updatedOptions[index].indexOf(option);
        if (optionIndex !== -1) {
          // Remove the option if already selected
          updatedOptions[index].splice(optionIndex, 1);

          // If no options are left, set back to an empty string
          if (updatedOptions[index].length === 0) {
            updatedOptions[index] = "";
          }
        } else {
          // Add the option if not already selected
          updatedOptions[index].push(option);
        }
      } else {
        if (updatedOptions[index] === option) {
          // Remove the option if re-selected
          updatedOptions[index] = option;
        } else {
          // Set the option if not selected
          updatedOptions[index] = option;
        }
      }
      return updatedOptions;
    });
  };

  // Add event listener to close the menu on click outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMenuVisible && !event.target.closest(".menu-div")) {
        setMenuVisible(false);
      }
      if (isLeftMenuVisible && !event.target.closest(".left-menu-div")) {
        setLeftMenuVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuVisible, isLeftMenuVisible]);

  //Scroll to bottom on new message
  useEffect(() => {
    const newTotalCost = messages
      .filter((message) => message.price !== undefined)
      .map((message) => {
        if (typeof message.price === "string") {
          const priceString = message.price.replace(/[^0-9.]/g, "");
          return parseFloat(priceString);
        } else {
          return parseFloat(message.price);
        }
      })
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    setTotalCost(newTotalCost);
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [messages]);

  //Voice Recording code
  const recognition = new window.webkitSpeechRecognition();

  recognition.onstart = () => {
    setIsListening(true);
    setIsMicGlowing(true);
  };

  recognition.onend = () => {
    setIsListening(false);
    setIsMicGlowing(false);
  };

  recognition.onresult = (event) => {
    let transcript = "";
    for (let i = event.resultIndex; i < event.results.length; i++) {
      if (event.results[i].isFinal) {
        transcript = event.results[i][0].transcript;
      } else {
        transcript += event.results[i][0].transcript;
      }
    }
    setInput(transcript);
  };

  const startRecording = () => {
    recognition.start();
  };

  const stopRecording = () => {
    recognition.stop();
    setIsListening(false);
    setIsMicGlowing(false);
  };

  const sendPreMessage = async (preMessage) => {
    if (roomId === undefined || roomId === " ") {
      // const data = preMessage.slice(0, 10);
      const data = {
        ROOM_NAME: preMessage.slice(0, 10),
      };
      // console.log('get room id=====>', data)
      // return false
      axiosInstance
        .post("/user/chat-rooms", data)
        .then((res) => {
          // console.log('room creation details', res?.data?.data?.ROOM_ID)
          // navigate(`/${res?.data?.data?.ROOM_ID}`)

          const Id = res?.data?.data?.ROOM_ID;
          // console.log('id', Id)
          const data = {
            message: preMessage,
            room_id: res?.data?.data?.ROOM_ID,
          };
          dispatch(userChat(data));
          setRouteId(`/rooms/${Id}`);
        })
        .catch((err) => {
          // console.log('failed to query', err)
          toast.error("failed to query");
        });
    } else {
      const data = {
        message: preMessage,
        room_id: roomId,
      };
      dispatch(userChat(data));
      // navigate(`/${res?.data?.data?.ROOM_ID}`)
    }
    setInput("");
  };

  //Send Message
  const sendMessage = async (e) => {
    e.preventDefault();
    if (input === "") {
      toast.error("Query field is empty!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: themeChanger === "dark-theme" ? "dark" : "light",
      });
      return false;
    }
    // setIsLoading(true);
    // console.log(serverUrl)
    function addSuffixIfContainsKeyword(inputString) {
      const keywords = ["plot", "chart", "graph"];

      // Check if any of the keywords is present in the inputString
      const containsKeyword = keywords.some((keyword) =>
        inputString.toLowerCase().includes(keyword)
      );

      // Add the suffix if a keyword is found
      const resultString = containsKeyword
        ? inputString + " in python"
        : inputString;

      return resultString;
    }
    const modifiedString = addSuffixIfContainsKeyword(input);

    
    // const querymessage = {
    //     message: modifiedString
    // }

    if (roomId === undefined || roomId === " ") {
      // const datas = input.slice(0, 10);
      // console.log('get room id=====>')
      // return false

      const data = {
        ROOM_NAME: input.slice(0, 10),
      };
      axiosInstance
        .post("/user/chat-rooms", data)
        .then((res) => {
          // console.log('room creation details', res?.data?.data?.ROOM_ID)

          // navigate(`/${res?.data?.data?.ROOM_ID}`)

          const data = {
            message: modifiedString,
            room_id: res?.data?.data?.ROOM_ID,
          };
          const Id = res?.data?.data?.ROOM_ID;
          console.log("this is hiting 2============>");
          // navigate(`/rooms/${res?.data?.data?.ROOM_ID}`);
          dispatch(userChat(data));
          setRouteId(`/rooms/${Id}`);
        })
        .catch((err) => {
          // console.log('failed to query', err)
          toast.error("failed to query");
        });
    } else {
      const data = {
        message: modifiedString,
        room_id: roomId,
      };

      console.log("with out room id heating===========>");
      dispatch(userChat(data));
      // dispatch(getChat(roomId))
    }

    setInput("");
  };

  

  //User selecting messages
  const toggleSelectMessage = (index, sender) => {
    // console.log('sender', sender);
    if (sender !== "User") {
      const messageIndex = selectedMessages.indexOf(index);
      if (messageIndex === -1) {
        setSelectedMessages((prevSelected) => [...prevSelected, index]);
      } else {
        setSelectedMessages((prevSelected) =>
          prevSelected.filter((msgIndex) => msgIndex !== index)
        );
      }
    }
  };

  // // console.log('chatmessages', messages)
  const handleCopyClick = (textToCopy) => {
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy?.message;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);
    setColors(textToCopy?.id);
    toast.success("text copied succefully");

    console.log("textcolor founder", textToCopy);
  };

  return (
    <>
      <div className={`parent-menu ${themeChanger}`}>
        <div className="top-second" ref={chatBodyRef}>
          <div className="chat__body">
            {messages?.length === 0 && (
              <div className="context_descripation">
                <img
                  src={themeChanger === "dark-theme" ? appaiIcon : applightIcon}
                  alt="bot_logo"
                />
                <h4 className="context_heading">Welcome again </h4>
                <p className="context_descripation_para">
                  I'm here to help, but I'm not perfect. Please tell me what you
                  think or choose a suggestion. Your feedback will help me to
                  better serve you in the future.
                </p>
              </div>
            )}
            {messages?.length !== 0 &&
              messages &&
              messages?.map((message, index) => (
                <div className="chat__message-container" key={index}>
                  <div className="chat__message">
                    <span className="chat__name ">
                      {message?.sender === "User" ? (
                        <img className="user-img" src={userImg} alt="user" />
                      ) : (
                        <img
                          className="user-img boatimgmob"
                          src={botImg}
                          alt="bot"
                        />
                      )}
                    </span>
                    <div
                      className={
                        message?.sender === "User"
                          ? "chat__content"
                          : "chat__dark"
                      }
                    >
                      {message?.sender === "ChatGPT" && (
                        <div className="msg_price_div">
                          <p className={`msg-price ${themeChanger}`}>
                            <span>
                              {!width && view && "Price:"}&nbsp;{" "}
                              {view && message?.price}{" "}
                            </span>
                            <span>
                              <div>
                                <Autocomplete
                                  size="small"
                                  sx={{
                                    width: width ? 150 : 300,
                                  }}
                                  fullWidth
                                  disableClearable={true}
                                  onChange={(e, newValue) =>
                                    handleSelectOptions(index, newValue)
                                  }
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some(
                                      (option) => inputValue === option.title
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push({
                                        inputValue,
                                        title: `Add "${inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  id="free-solo-with-text-demo"
                                  options={top100Films}
                                  getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    return option.title;
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option.title}</li>
                                  )}
                                  freeSolo
                                  style={{ color: "yellow" }}
                                  renderInput={(params) => (
                                    <TextField
                                      variant="outlined"
                                      color="success"
                                      {...params}
                                      size="small"
                                      style={{ color: "yellow" }}
                                      sx={{
                                        "& label.Mui-focused": {
                                          color: "green",
                                        },
                                        "& label": {
                                          color:
                                            themeChanger === "dark-theme"
                                              ? "white"
                                              : "black",
                                        },
                                        "& .MuiInput-underline:after": {
                                          borderBottomColor: "green",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          "& input": {
                                            color:
                                              themeChanger === "dark-theme"
                                                ? "white"
                                                : "black",
                                          },
                                          "& fieldset": {
                                            borderColor:
                                              themeChanger === "dark-theme"
                                                ? "white"
                                                : "black",
                                          },
                                          "&:hover fieldset": {
                                            borderColor:
                                              themeChanger === "dark-theme"
                                                ? "white"
                                                : "black",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "green",
                                          },
                                        },
                                      }}
                                      label="Add Heading Tag"
                                    />
                                  )}
                                />
                              </div>
                            </span>
                            <TextToSpeech text={message?.message} />
                            <ContentCopyIcon
                              style={{
                                color:
                                  colors === message?.id ? "green" : "grey",
                              }}
                              onClick={() => handleCopyClick(message)}
                            />
                          </p>
                        </div>
                      )}
                      <div
                        onClick={() =>
                          toggleSelectMessage(index, message?.sender)
                        }
                        className={
                          selectedMessages.includes(index)
                            ? `selecteds ${themeChanger}`
                            : `no_contett_selected ${themeChanger}`
                        }
                      >
                        {selectedMessages.includes(index) && (
                          <Checkbox checked={true} readOnly />
                        )}
                        {message?.message?.startsWith("data:image") ? (
                          <img
                            style={{ width: "100%" }}
                            src={message.message}
                            alt="Graph"
                          />
                        ) : message?.message?.includes("|") ? (
                          <pre className={`chat__pro ${themeChanger}`}>
                            {message.message}
                          </pre>
                        ) : message?.message?.includes("\n") ? (
                          // message.message.split('\n').map((paragraph, index) => (
                          <pre
                            key={index}
                            className={`chat__pro ${themeChanger}`}
                            style={{
                              color:
                                themeChanger === "dark-theme" ||
                                selectedMessages.includes(index)
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {message?.message}
                          </pre>
                        ) : (
                          // )
                          // )
                          <p>{message?.message}</p>
                        )}
                      </div>
                      {message?.sender === "ChatGPT" && (
                        <div className="flex__similarity">
                          <span className="left_container_similarity">
                            {message?.sourceDocument !== "0" && (
                              <p>
                                Name:{" "}
                                {message?.sourceDocument?.split("Docs/")[1]} ||
                                Pages: {message?.sourcePage}{" "}
                              </p>
                            )}
                            {
                              message?.similarityScore &&
                                message?.similarityScore !== "0" && (
                                  // <p>Search Result: {message?.similarityScore?.toFixed(2) + "%"} || {urlname}</p>
                                  <p>
                                    {/* Search Result: {message?.similarityScore != null ? !isNaN(parseFloat(message.similarityScore)) ? parseFloat(message.similarityScore).toFixed(2) + " % " : message.similarityScore : ''} */}
                                    {/* ||  */}
                                    {urlname}
                                  </p>
                                )
                              // {/^\d+$/.test(message?.similarityScore?) ? message?.similarityScore?.toFixed(2) + "%" : message?.similarityScore?  }
                            }
                          </span>
                          <span className="right_container_similarity">
                            {message?.CurrentDate !== "0" && (
                              <p>
                                {message?.CurrentDate?.split("-")
                                  .reverse()
                                  .join("-")}
                              </p>
                            )}
                            {message?.currentTime !== "0" && (
                              <p>
                                {
                                  message?.currentTime?.match(
                                    /\d{2}:\d{2}:\d{2}/
                                  )[0]
                                }{" "}
                              </p>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            {loader && <Loader />}
            {
              // initialLoad && (
              messages?.length === 0 && (
                <div className="gridInitial">
                  <div className="flexInitial">
                    <InitialContent
                      message="Analyzing the growth potential of renewable energy stocks in the current market."
                      sendPreMessage={sendPreMessage}
                    />
                    <InitialContent
                      message="Analyzing the current trends in real estate markets and potential investment opportunities."
                      sendPreMessage={sendPreMessage}
                    />
                    <InitialContent
                      message="The effects of government policies and regulations on financial markets and investors."
                      sendPreMessage={sendPreMessage}
                    />
                  </div>
                </div>
              )
              // )
            }
          </div>
        </div>
        {/* <ExportToWord element="content" filename="exported-doc" /> */}

        {/* bottom asking section */}

        <div className="bottom-div" id="ChatDiv">
          <form onSubmit={sendMessage} className="qns_type_field">
            <input
              type="text"
              id="ChatTxt"
              className={`f-control ${themeChanger}`}
              placeholder="Tell me what you think..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </form>
          {views && (
            <AvatarModal src="https://ashish-avatar-v3.netlify.app/">
              <AiFillRobot />
            </AvatarModal>
          )}
          <button onClick={sendMessage} className={`send ${themeChanger}`}>
            <LiaTelegram />
          </button>
          <button
            onClick={isListening ? stopRecording : startRecording}
            className={`send ${themeChanger} ${isMicGlowing ? "glowing" : ""}`}
          >
            <BsFillMicFill />
          </button>
          {isListening && (
            <span className="listening-text">
              <i
                className="fa fa-spinner fa-spin"
                style={{ color: "white" }}
              ></i>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
