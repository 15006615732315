import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { Close } from '@mui/icons-material';
import SettingsInputCompositeOutlinedIcon from '@mui/icons-material/SettingsInputCompositeOutlined';
import { useRef, useState } from 'react';
import '../../../components/SideNavlayout/SidebarLayout.css'
import Loader from '../../../components/Loader/Loader';
import axios from 'axios';
import { CgWebsite } from 'react-icons/cg'
import { VscFilePdf } from 'react-icons/vsc'
import { useData } from '../../../DataContext';
import { getFromLocalStorage } from '../../../utils/localStorage';


const headTrainings = {
    borderBottom:'1px solid rgb(123, 114, 101)',
    padding: '10px'
}

const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding:'1rem'
}



const Trainings = ({ serverUrl, uploadDocApiCall, isLoading }) => {
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const SCRAP_URL = "https://aidemo.infusai.com:8503";
    const inputRef = useRef(null);
    const { setScrapUrl, themeChanger } = useData();
    const token = getFromLocalStorage('authtoken')



    const addScrapUrl = () => {
        //console.logurl);
        axios.post(`${SCRAP_URL}/ReadURL`, { url })
            .then(res => {
                //console.logres);
                setScrapUrl(url);
                setOpen(false);
                alert("URL scrapped successfully")
            })
            .catch(err => {
                alert(err);
            })
        setUrl('');
    }

    //Train the model
    const handleSelectedFile = () => {
        const file = inputRef.current.files[0];
        //console.log'filedetails',file);
        const form = new FormData();
        form.append('files', file);
        let settings = {};

        if (file.type === "application/pdf" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            settings = {
                // url: `${serverUrl}/uploadFiles`,
                url:`https://api.infusai.com/uploadFiles`,
                // url:`${process.env.BACKEND_URL}/uploadFiles`,
                method: 'POST',
                timeout: 0,
                processData: false,
                mimeType: 'multipart/form-data',
                contentType: false,
                headers:{
                    authorization: `Bearer ${token}`},
                data: form
            };
            // alert('inside the pdf file');
            uploadDocApiCall(settings);
            // dispatch(uploadFiles(form))
        } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
            settings = {
                // url: `https://aidemo.infusai.com:8504/uploadFile`,
                url:`https://api.infusai.com/uploadFiles`,
                // url:`${process.env.BACKEND_URL}/uploadFiles`,
                method: 'POST',
                timeout: 0,
                processData: false,
                mimeType: 'multipart/form-data',
                headers:{
                    authorization: `Bearer ${token}`},
                contentType: false,
                data: form
            }
            uploadDocApiCall(settings);
        } else {
            alert("Type not supported");
        }
    };

    return (
        <>
            <li>
                <span onClick={() => setOpen(true)} className='download_pdf'>
                    <SettingsInputCompositeOutlinedIcon />
                    <span className='menu-text'>Train the model</span>
                </span>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    sx={modal}
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                        <div className='modal_papers' style={{ background: themeChanger === 'dark-theme' ? '#111111' : 'rgb(196, 204, 208)' }}>
                            <div className="close_icon_modal">
                            <Close onClick={() => setOpen(false)} style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }} className='new-chat-close-btn' />

                            </div>
                            <div className="modal-header" style={{width:'100%'}}>
                                <h4 className="modal-title" style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }} id="transition-modal-title">Train the model</h4>
                            </div>
                            <div className="modal-body" style={{width:'auto'}}>
                                {isLoading && <Loader />}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table " style={{ color: themeChanger ? 'white' : '#45494e' }}>
                                                <tbody>
                                                    <tr >
                                                        <td className="font-14" style={{ border: 'none' }}>
                                                            <div sx={headTrainings}>
                                                                <VscFilePdf className='icon-download' />
                                                                <span className='menu-text' style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }}>{" "}Train Documents</span>
                                                            </div>
                                                            <br />
                                                            <span>
                                                                <input ref={inputRef}

                                                                    type="file"
                                                                    id="fileInput"
                                                                    onChange={handleSelectedFile} />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <br />
                                                    <br />
                                                    <tr >
                                                        <td className="font-14">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div sx={headTrainings}>
                                                                        <CgWebsite className='icon-download' />
                                                                        <span className='menu-text' style={{ color: themeChanger === 'dark-theme' ? 'white' : '#45494e' }}>{" "}Train with a website url</span>
                                                                    </div>
                                                                    <br />
                                                                    <input className="form-control input-new-chat" style={{ border: 'none', background: themeChanger !== 'dark-theme' && 'white' }} placeholder="Add Web Scrapping Url" type="text" name="category-name" value={url} onChange={e => setUrl(e.target.value)} />
                                                                    <div className="footer-modal">
                                                                        <button type="button" className="btn btn-primary waves-effect waves-light save-category btn-sm" onClick={addScrapUrl}>Add URL</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal>
            </li>
        </>
    )
}

export default Trainings
