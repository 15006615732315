import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { DataProvider } from './DataContext';
import { Provider } from "react-redux";
import { store } from './Store/Store';

const pca = new PublicClientApplication({
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPII) => {
                //console.log(message)
            },
            logLevel: "Verbose"
        }
    }
});

pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        pca.setActiveAccount(event.payload.account);
    }
});

ReactDOM.render(
    <>
        <DataProvider>
            <Provider store={store}>
                <App msalInstance={pca} />
            </Provider>
        </DataProvider>
    </>,
    document.getElementById('root')
);