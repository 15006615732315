import { useData } from "../../../DataContext"

const InitialContent = ({ message, sendPreMessage }) => {
    const {themeChanger}=useData()
    return (
        <>
            <div className= {`initial__content ${themeChanger}`}    onClick={() => sendPreMessage(message)}>
                <span className='initial-text'>{message}</span>
            </div>
        </>
    )
}

export default InitialContent