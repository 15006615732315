import axiosInstance from "../../../utils/axiosInstance";
// import querystring from "query-string";

export const userLoginThunk = async (url, datas, thunkAPI) => {
    try {
        // Create a configuration object with additional headers
        const config = {
            headers: {
                'Content-Type':'application/x-www-form-urlencoded',
                // 'X-Api-Key':'0ac4da8e-61c3-43a6-881f-fd452166bf8e',
                'x-api-key':`${datas?.x_api_key}`,
                // Other headers if needed
                // $2a$12$RBSmzoHiOwikFOZ02H/WAOiU2EHLkTbLYf8uChOW5J32a20fELoHi
            },
        };

        // console.log('userdetailsdata',datas)
        const resp = await axiosInstance.post(url, datas,config);
        return resp.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });
        // return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const createTokenFOrSocialThunk = async (url, data, thunkAPI) => {
    try {

        
        const resp = await axiosInstance.post(url, data);
        // console.log('tokendetailsresp',resp);
        return resp?.data;
    } catch (error) {
        // console.log('errorfolder',error)
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });
    }
}
export const createFolderThunk = async (url, data, thunkAPI) => {
    try {

        
        const resp = await axiosInstance.post(url, data);
        return resp;
    } catch (error) {
        // console.log('errorfolder',error)
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });
    }
}
export const uploadFilesThunk = async (url, data, thunkAPI) => {
    try {
        const config ={
            headers:{
                timeout: 0,
                processData: false,
                mimeType: 'multipart/form-data',
                contentType: false,
            }
        }
        // console.log('config',config);
        // console.log('data',data)
        const resp = await axiosInstance.post(url, data,config);
        return resp;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const userChatThunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.post(url, data);
        return resp;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const getChatThunk = async (url, thunkAPI) => {
    try {
        const resp = await axiosInstance.get(url);
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const deleteDocsThunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.delete(url);
        return resp;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const countDocsThunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.get(url, data);
        return resp?.data?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const getRoomThunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.get(url, data);
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const addRoomThunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.post(url, data);
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const updateRoomThunk = async (url, data, thunkAPI) => {
    try {
        const resp = await axiosInstance.put(url, data);
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const deleteRoomThunk = async (url,thunkAPI) => {
    try {
        const resp = await axiosInstance.delete(url);
        // console.log('resp delete room',resp)
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}


export const tokenVlaidationThunk = async (url,thunkAPI) => {
    try {
        const resp = await axiosInstance.post(url);
        // console.log('resp delete room',resp)
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}

export const getUserThunk = async (url,thunkAPI) => {
    try {
        const resp = await axiosInstance.post(url);
        // console.log('resp delete room',resp)
        return resp?.data;
    } catch (error) {
        // return thunkAPI.rejectWithValue(error.response)
        return thunkAPI.rejectWithValue({ message: error.message, status: error.response?.status });

    }
}
