import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slice/user/authSlice.js";
// import messageSlice from "./slice/message/messageSlice.js";

export const store = configureStore({
    reducer:{
        auth:authSlice,
        // message:messageSlice

    }
})