import * as  React from 'react'
import './outerlink.css'
import { useDispatch, useSelector } from 'react-redux';
import { tokenValidation } from '../../Store/Slice/user/authSlice';
import Loader from '../../components/Loader/Loader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    height: 300,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 24,
    p: 4,
};

const OuterLink = () => {
    const dispatch = useDispatch()
    const authToken = new URLSearchParams(window.location.search).get('authtoken');
    const loader = useSelector((state) => state && state?.auth && state?.auth?.isLoading);
    const [open, setOpen] = React.useState(false);


    const logintoken = useSelector((state) => state && state?.auth && state?.auth?.token);

    // console.log('social token', logintoken);




    React.useEffect(() => {
        if (logintoken) {
            // console.log('it is a valid social token');
            // navigate('/');
            // window.location.reload();
            setOpen(true)
        }

    }, [logintoken])

    React.useEffect(() => {
        if (authToken) {
            dispatch(tokenValidation(authToken))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])
    return (
        <>
            {loader && <Loader />}
            {open && <BasicModal open={open} tokens={logintoken} />}
        </>
    )
}

export default OuterLink



function BasicModal({ open, tokens }) {
    return (
        <>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Session Has Expaired
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {tokens?.message}
                    </Typography>
                </Box>
            </Modal>
        </>
    )

}