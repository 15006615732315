import { Dialog } from "@mui/material"

const Loader = () => {
    const open = true;
    return (
        <div style={{ outline: 'none' }}>
            <Dialog open={open}>
                <div id="loader" className="loader">
                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{ color: 'white' }}></i>
                </div>
            </Dialog>
        </div>
    )
}

export default Loader