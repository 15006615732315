import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import { Close, Delete } from '@mui/icons-material';
import './ShowModel.css'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Loader from '../../../components/Loader/Loader';
import { Button } from '@mui/material';
import { useData } from '../../../DataContext';
// import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { countDocs } from '../../../Store/Slice/user/authSlice';

const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: 'none'
    // p:4
}


const ShowDelModels = ({ serverUrl, newDocs, deleteDoc, fetchData, isLoading }) => {
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('docs');
    const { themeChanger } = useData()
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        dispatch(countDocs())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <li>
                <span onClick={() => setOpen(true)} className='download_pdf'>
                    <InsertDriveFileOutlinedIcon />
                    <span className='menu-text'>Show Documents</span>
                </span>
                <div style={{ outline: 'none', border: 'none' }}>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        sx={modal}
                        open={open}
                        onClose={() => setOpen(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <div className='modal_papers' style={{ background: themeChanger === 'dark-theme' ? '#111111' : 'rgb(196, 204, 208)' }}>
                            <div className="close_icon_modal">

                                <Close onClick={() => setOpen(false)} style={{ color: 'white' }} className='new-chat-close-btn' />
                            </div>
                            <div style={{ width: '100%' }}>
                                <Button className="modal-title" variant="text" style={{ color: activeTab === 'docs' ? 'green' : '#989a9c', outline: 'none' }} onClick={() => setActiveTab('docs')} id="transition-modal-title">Documents List</Button>
                                <Button className="modal-title" variant="text" style={{ color: activeTab === 'scrap' ? 'green' : '#989a9c', outline: 'none', display: 'none' }} onClick={() => setActiveTab('scrap')} id="transition-modal-title">
                                    {/* {models?.csvList?.length && */}
                                    CSV List
                                    {/* } */}
                                </Button>
                            </div>
                            <div className="modal-body" style={{ width: 'auto', overflowY: 'scroll', height: '45vh' }}>
                                {isLoading && <Loader />}
                                {
                                    activeTab === 'docs' ?
                                        (<div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered">
                                                        <tbody>
                                                            {newDocs && newDocs?.length !== 0 && newDocs?.map((model, i) => (
                                                                <tr key={model?.DOCUMENT_ID}>
                                                                    <td className="font-14">
                                                                        <h5 className="text-primary" key={i} id="transition-modal-description">{model?.document_file_name
                                                                        }</h5>
                                                                    </td>
                                                                    <td>
                                                                        <Delete onClick={() => deleteDoc(model)} className='show-del-btn' />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        ) : (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-bordered">
                                                            <tbody>
                                                                {newDocs && newDocs?.length !== 0 && newDocs?.map((model, i) => (
                                                                    <tr key={i}>
                                                                        <td className="font-14">
                                                                            <h5 className="text-primary" key={i} id="transition-modal-description">{model?.document_file_name
                                                                            }</h5>
                                                                        </td>
                                                                        <td>
                                                                            <Delete onClick={() => deleteDoc(model)} className='show-del-btn' />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                }
                            </div>
                        </div>
                    </Modal>
                </div>
            </li>

        </>
    )
}

export default ShowDelModels