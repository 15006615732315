import React, { useState, useEffect } from "react";
import './TextToSpeech.css';
import playedImg from '../../../images/img-mute.png'
import mutedImg from '../../../images/img-unmute.png'

const TextToSpeech = ({ text }) => {
    const [utterance, setUtterance] = useState(null);
    const [isPlayed, setIsPlayed] = useState(false);

    useEffect(() => {
        const synth = window.speechSynthesis;
        const u = new SpeechSynthesisUtterance(text);
        setUtterance(u);
        return () => {
            synth.cancel();
        };
    }, [text]);

    const handlePlay = () => {
        const synth = window.speechSynthesis;
        synth.speak(utterance);
        setIsPlayed(true);
    };

    const handleStop = () => {
        const synth = window.speechSynthesis;
        synth.cancel();
        setIsPlayed(false);
    };

    return (
        <>
            {isPlayed ? <button onClick={handleStop} className="tts-button"><img className="tts-img" src={playedImg} alt="played" /></button> :
                <button onClick={handlePlay} className="tts-button"><img className="tts-img" src={mutedImg} alt="muted" /></button>
            }
        </>
    );
};

export default TextToSpeech;
