

// export const backendUrl = "http://localhost:3000/api/";
export const backendUrl = "https://nodejs-docker-backend.azurewebsites.net/api/";

// export const backendUrl = "https://nodejstestingapi.azurewebsites.net/api";
// export const backendUrl = "https://ai-nodejs.onrender.com/api/";

// export const backendUrl = "http://0.0.0.0:8000"
export const Homepage = "http://localhost:3000";
export const ImageBackend = "http://localhost:8001/";
export const EncryptionKey = "infusai@RegistrationKey#";
export const ReferalEncryptionKey = "Fnm@Referral#";
export const GOOGLECLIENTID = "747910821149-2htccc237lha23kvpgr07m44u1skuvv4.apps.googleusercontent.com";
export const GOOGLECLIENTSECRET = "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";
export const LINKEDINCLIENTID = "77dhwuyoqrd5ep";
